import React, { Component } from 'react';

import './Contact.scss';
class Contact extends Component {

    render() {

        return (


            <div className="bvg-contact">
                <h1 className={'bvg-contact-header'}>Contact Us</h1>

                <div className={'bvg-contact-container'}>
                    <h2>Bukka Venu Gopal Communications Office: </h2>
                    <h4>Address:</h4>
                    <p>BVG Office, <br />At Dhodi to Ootpally Road Sringeri Society, <br />Ootpally (v), Shamshabad (M), <br />R.R.District, Telangana, 501218</p>

                    <h4>Office Phone: </h4>
                    <p>(040) - 24501249</p>

                    <h4>Email:</h4>
                    <p>bvgoffice1249@gmail.com</p>
                </div>
            </div>
        );
    }
}

export default Contact;
