import React, { useState, useEffect } from "react";
import Gallery from "react-photo-gallery";
import { getImageSize } from 'react-image-size';
import './Home.scss';
import './Shared.scss'

let HomeData = {
    'bannerTitle': 'हार नहीं मानूंगा, रार नहीं थानुगा',
    'bannerTitleBy': '-- Atal Bihari Vajpayee',
    'vmTitle': 'Vission - Mission',
    'vmText': ["My mission is ", <b>to work with dedication and selflessness </b>, "towards achieving the goal of providing good governance to the people and ensuring equal development and welfare for all. -- ", <b>B.V.G</b>],
    'raTitle':'Recent activities',
    'raText': ["Bukka Venu Gopal garu met Andra Pradesh CM Nara Chandra Babu Naidu garu and Deputy CM Chinna Rajappa garu at Amaravathi,",<b> asking MLA ticket for Telugu Desam Party for Rajendra nagar constituency.</b>],
    'galleryTitle': 'Latest from gallery',
    'gallery': [
        '/images/dharna_bvg_1.jpg',
        '/images/dharna_bvg_2.jpg',
        '/images/bvg_nl_2.jpg',
        '/images/bvg_nl_3.jpg',
        '/images/bvg_laxman_1.jpg',
        '/images/bvg_nl_1.jpg',
        '/images/bvg_sl_1.jpg',
        '/images/bvg_vg_2.jpg',
        '/images/swami1.jpg',
        '/images/bvg_chinna_1.jpg',
        '/images/withleader40.jpg'
    ]
};

function HomeComponent() {
    const [stillLoading, setStillLoading] = useState(true);
    const [renderedPhotosObject, setRenderedPhotosObject] = useState([]);

    const createGalleryList = async str => {
        try {
            const {width, height} = await getImageSize(str);
            return {
                src : str,
                width : width || 1,
                height : height || 1
            }
        } catch (e) {
            return {
                src : str,
                width :  1,
                height : 1
            }
        }
    };

    const addDimensionsToGalleryList = async() => {
        const unresolvedPromises = HomeData.gallery.map(createGalleryList);
        const resolvedPromise = await Promise.all(unresolvedPromises);
        setRenderedPhotosObject(resolvedPromise);
        setStillLoading(false);
    };

    useEffect(() => {
        addDimensionsToGalleryList();
    }, [])

    return (
        <div className="bvg-home">
            <div className="bvg-banner-container">
                <div className="bvg-banner">
                </div>
                <header className="bvg-home-header">
                    <h1>
                        <p className="head1">{HomeData.bannerTitle}</p>
                        <p className="byline">{HomeData.bannerTitleBy}</p>
                    </h1>
                </header>
            </div>

            <div className="bvg-vision-mission">
                <h2>{HomeData.vmTitle}</h2>
                <p>{HomeData.vmText}</p>
            </div>

            <div className="bvg-home-gallery">
                <h2>{HomeData.galleryTitle}</h2>
                { (!stillLoading) ? <Gallery photos={renderedPhotosObject} direction={"row"} /> :  <div> Images are loading.. </div>}
            </div>
        </div>
    );
}
export default function Home() {
    return (
        <>
            <HomeComponent />
        </>
    )
};
