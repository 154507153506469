import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, NavLink, Navigate, Routes } from "react-router-dom";

import './Navigation.scss';
import Home from "./Home";
import AboutBVG from "./AboutBVG";
import Gallery from "./Gallery";
import Contact from "./Contact";

function NavItem({text, to}) {
    return (
        <li >
            <NavLink to={to}>{text}</NavLink>
        </li>
    );
}

function NavBar() {
    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
        }
    }, [isInitialRender]);
    return (
        <Router>
            <div>
                <ul id={'nav'} className={'header'}>

                    <NavItem text={'Home'} to={"/Home"} className={'bvg-nav-item'}/>
                    <NavItem text={'About BVG'} to={"/AboutBVG"} className={'bvg-nav-item'}/>
                    <NavItem text={'Gallery'} to={"/Gallery"} className={'bvg-nav-item'}/>
                    <NavItem text={'Contact us'} to={"/ContactUs"} className={'bvg-nav-item'}/>

                </ul>
                <hr />
                    <div className="content">
                        <Routes>
                            <Route exact path="/" element={ <Home /> }/>
                            <Route path="/Home" element={ <Home /> }/>
                            <Route path="/AboutBVG" element={ <AboutBVG /> } />
                            <Route path="/Gallery" element={ <Gallery /> } />
                            <Route path="/ContactUs" element={ <Contact /> } />
                        </Routes>
                        {isInitialRender? <Navigate from="/" to="/Home" /> : ''}
                </div>
            </div>
        </Router>
    );
}

export default function Navigation() {
    return (
        <>
            <NavBar />
        </>
    )
};
