import React, { Component } from 'react';
import './AboutBVG.scss';
import './Shared.scss'


const AboutBVGData = {
    'bannerTitle': 'A leader of ideology for the younger generation.',
    'fbTitle': 'Family Background',
    'fbp1': 'Bukka Venugopal, the youngest son of Sri. Kishtaiah and Smt. Sakku Bhai, was born on October 14th, 1971 in Narkhuda(V), Shamshabad Mandal, Rajendra Nagar constituency. His father was a freedom fighter and social activist who worked towards resolving the various issues present in the village. Since childhood, Bukka Venugopal was taught the values of truth, honesty, discipline, and loyalty by his father.',
    'fbp2' : 'Motivated by the ideals of the Bharatiya Janata Party (BJP), Bukka Venugopal\'s elder brother, Shri Bukka Prakhar ji, became a member of the party since its inception and encouraged the youth in Shamshabad Mandal to join as well. Following his brother\'s lead, Venugopal joined the party in response to his brother\'s call. From 1990 to 1996, Shri Bukka Prakhar ji served as the Mandal General Secretary of the Bharatiya Janata Party and organized various programs in the Mandal, along with Badda Bal Reddy Ji, Kisan Reddy Ji, Kova Laxman Ji, and Dr. Prem Raj Yadav Ji, to inspire the youth to join the party. Venugopal also actively participated in all major party programs, including the "Rath Yatra" movement initiated by Shri L.K. Advani Ji. After his brother\'s passing in 1996, Venugopal stepped away from politics until 2000, when he joined the ruling state party, Telugu Desam Party (TDP), which was in alliance with the Bharatiya Janata Party. From 2000 to 2019, he provided selfless service to the people and the party and worked tirelessly to strengthen the TDP in the Rajendranagar constituency, earning the trust and love of the people.',
    'paTitle': 'Political Activities',
    'pap1':[<b>In 2001, Venu Gopal garu was elected as an MPTC (Mandal Parishad Territorial Candidate) member for Narkhuda village by unanimous decision.</b>," At the young age of 30, he served the people of Narkhuda from 2001 to 2005. In 2005, he was again elected unanimously, this time as the TDP Mandal President for Shamshabad Mandal for a two-year term. He was once again unanimously elected as TDP Mandal President for Shamshabad Mandal in 2007 for another two-year term."],
    'pap2':[<b>In 2009, he was appointed as the Core Committee Member</b>," and Election Coordinator for the Rajendranagar constituency, where the Telugu Desam Party won an MLA seat with a majority of 7450 votes over the Congress party during the Assembly and Parliament elections. Despite being a core committee member and a potential candidate for the MLA seat in the 2009 elections, he made the selfless decision to sacrifice his candidacy to support the party's decision and Mr. T. Prakash Goud's candidacy. Nevertheless, he remained dedicated to the welfare of the people in his village and the success of the Telugu Desam Party in the constituency. During the 2009 and 2014 state elections, Venu Gopal garu played a significant role in helping the Telugu Desam Party secure seats against tough competition from various other parties."],
    'pap3':["Following the 2009 elections, Venu Gopal garu worked alongside Telugu Desam Party workers to raise awareness about the ", <b>Right to Vote</b>," and encouraged many to join the party and strengthen it. This effort led to the party winning another MLA seat during the 2014 elections with an increased majority of 25,917 votes over the Congress party. In 2015, Venu Gopal garu contested in the Telangana state MLC elections from Ranga Reddy District and secured 114 votes. Despite the challenging times for the Telugu Desam Party during that period and offers from various state parties, Venu Gopal garu remained committed to the success of T-TDP as a loyal party worker."],
    'pap4':["For over 10 years, from 2009 to 2019, Venu Gopal garu served as T-TDP State Secretary. During this time, he also acted as a party observer for Andole, Makthal, and Sanathnagar constituencies, as well as serving as an Election Coordinator for Jagadgirigutta municipal corporation elections."],
    'bjpActivitiesTitle':'Rejoining the BJP party',
    'bjp1':[<b>In 2019, I was inspired by the leadership of our Prime Minister Narendra Modi Ji and Home Minister Amit Shah Ji to serve the people and strengthen the Bharatiya Janata Party in Telangana State. With a commitment to provide my full-fledged and selfless service to the party, I decided to join the BJP. I am prepared to work hard to uplift the Bharatiya Janata Party in our constituency, which will ultimately benefit the people and allow them to reap the benefits of great leadership.</b>],
    'bjpActivities': ['I have been actively involved in various roles within the BJP party in Telangana State, and I am proud to have contributed to the party\'s success in recent elections. Here are some examples of my contributions:'],
    'bjpActivities1': [<ul>
            <li>As the Prachara Vibhag convenor of Praja Sangrama Yatra, I mobilized local party workers from different constituencies that were covered during the yatra. I organized welcome meetings to convey the party's agenda from Telangana State party president Bandi Sanjay Garu.</li>
            <li>During the recent GHMC Elections, I served as the Incharge for Ram Nagar division. I motivated and inspired local youth to participate in the campaign, and I helped K Ravi Chary win the seat for the BJP party.</li>
        <li>During the Huzurabad Bi-Election, I was made Incharge of Katrapalli village, which is known for its election-related disputes. I worked diligently to mobilize local youth and spread the word about the party's agenda among them, inspiring them to work during the campaign without any conflicts.</li>
            <li>During the Nagarjuna Sagar Bi-Election, I was appointed as the Incharge of Tirumalagiri mandal. I contributed to the campaign for the BJP candidate and was able to effectively spread the party's message among the masses.</li>
            <li>During the pandemic, I made myself available to people from different villages in Shamshabad Mandal (Rajendra Nagar constituency) and provided them with food and medical supplies. I ensured that help reached people multiple times during the lockdown period.</li></ul>],
    'puaTitle':'Public Activities',
    'puap1' : 'Venu Gopal garu has organized district level competitions in various fields like Body Building, Cricket and Volley Ball for encouraging the youth in sports and for the upliftment of people in need. In Constituency region, he has helped many physically challenged people by providing funds to establish a business for their livelihood. One among such people is Mr. Krishna from Shamshabad, who has started a Pan-shop with the funds.',
    'puap2' : 'Venu Gopal garu has also organized blood donation camps and encouraged people to donate blood for needy for situations like Bomb blast in Dilsukhnagar. He helped the constituency people in different ways like funding for education of students, renovation of government schools, construction of temples and mosques etc., in Shamshabad Mandal area.',
    'puap3' : 'During the tenure of Congress government, as there was not much development in many villages, Venu Gopal garu has taken initiation to develop few things like deploying Hi-Max lights in some streets in Kavvaguda village from Shamshabad Mandal. To support the fishermen of Kotwalguda village, he has donated high quality nets.',
    'puap4' : ' Venu Gopal garu is a staunch advocate for the equality of the BC community in Telangana State. Presently, he serves as the Working President for TEMKAJAC (Telangana Munnurkapu Joint Action Committee). Earlier, he was elected as the District President for MUNNURU KAPU Community in Ranga Reddy District. Venu Gopal Garu is a dedicated leader who consistently strives for the welfare and progress of the people of Shamshabad. He is well-known for his amicable relations with his family, friends, party workers, and business partners in the Rajendra Nagar Constituency.',
};

class AboutBVG extends Component {

    render() {
        return (

            <div className="bvg-about">
                <div className="bvg-banner-container">
                    <div className="bvg-banner">
                    </div>
                    <header className="bvg-home-header">
                        <h1><p className="head1">{AboutBVGData.bannerTitle}</p></h1>
                    </header>
                </div>

                <div className={'bvg-background'}>

                    <h2>{AboutBVGData.fbTitle}</h2>
                    <p>{AboutBVGData.fbp1}</p>
                    <p>{AboutBVGData.fbp2}</p>

                    <h2>{AboutBVGData.paTitle}</h2>
                    <div>
                        <p>{AboutBVGData.pap1}</p>
                        <p>{AboutBVGData.pap2}</p>
                        <p>{AboutBVGData.pap3}</p>
                        <p>{AboutBVGData.pap4}</p>
                    </div>
                    <h3>{AboutBVGData.bjpActivitiesTitle}</h3>
                    <div>
                        <p>{AboutBVGData.bjp1}</p>
                        <p>{AboutBVGData.bjpActivities}</p>
                        {AboutBVGData.bjpActivities1}
                    </div>

                    <h2>{AboutBVGData.puaTitle}</h2>
                    <div>
                        <p>{AboutBVGData.puap1}</p>
                        <p>{AboutBVGData.puap2}</p>
                        <p>{AboutBVGData.puap3}</p>
                        <p>{AboutBVGData.puap4}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutBVG;
