import React, {useEffect, useState} from 'react';
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {getImageSize} from "react-image-size";
import './Gallery.scss';

let GalleryData = {
    'moreImagesText': 'See more images in Gallery...',
    'moreImagesLink': '/Gallery',
    'imgAltPrefix': 'bukka-venugopal-img-',
    'defaultGalleryList': [
        '/images/dharna_bvg_1.jpg',
        '/images/dharna_bvg_2.jpg',
        '/images/bvg_nl_2.jpg',
        '/images/bvg_nl_3.jpg',
        '/images/bvg_laxman_2.jpg',
        '/images/bvg_sl_2.jpg',
        '/images/bvg_sl_3.jpg',
        '/images/bvg_shiva.jpg',
        '/images/bvg_dathatreya.jpg',
        '/images/bvg_solo_1.jpg',
        '/images/bvg_solo_2.jpeg',
        '/images/bvg_solo_3.jpeg',
        '/images/bukka_venugopal_withcbn_2.jpeg',
        '/images/bukka_venugopal_withcbn_3.jpeg',
        '/images/bukka_venugopal_withcbn_4.jpeg',
        '/images/bukka_venugopal_withcbn_5.jpeg',
        '/images/public2.jpg',
        '/images/public3.jpg',
        '/images/public4.jpg',
        '/images/public5.jpg',
        '/images/public6.jpg',
        '/images/public7.jpg',
        '/images/public8.jpg',
        '/images/public9.jpg',
        '/images/public11.jpg',
        '/images/public12.jpg',
        '/images/public13.jpg',
        '/images/public14.jpg',
        '/images/public15.jpg',
        '/images/public16.jpg',
        '/images/public17.jpg',
        '/images/public19.jpg',
        '/images/public20.jpg',
        '/images/public21.jpg',
        '/images/public22.jpg',
        '/images/public27.jpg',
        '/images/public28.jpg',
        '/images/withleader1.jpg',
        '/images/withleader2.jpg',
        '/images/withleader3.jpg',
        '/images/withleader4.jpg',
        '/images/withleader5.jpg',
        '/images/withleader6.jpg',
        '/images/withleader7.jpg',
        '/images/withleader8.jpg',
        '/images/withleader9.jpg',
        '/images/withleader10.jpg',
        '/images/withleader11.jpg',
        '/images/withleader12.jpg',
        '/images/withleader13.jpg',
        '/images/withleader14.jpg',
        '/images/withleader15.jpg',
        '/images/withleader16.jpg',
        '/images/withleader17.jpg',
        '/images/withleader18.jpg',
        '/images/withleader19.jpg',
        '/images/withleader20.jpg',
        '/images/withleader21.jpg',
        '/images/withleader22.jpg',
        '/images/withleader24.jpg',
        '/images/withleader25.jpg',
        '/images/withleader26.jpg',
        '/images/withleader27.jpg',
        '/images/withleader28.jpg',
        '/images/withleader30.jpg',
        '/images/withleader31.jpg',
        '/images/withleader32.jpg',
        '/images/withleader33.jpg',
        '/images/withleader36.jpg',
        '/images/withleader37.jpg',
        '/images/withleader40.jpg',
        '/images/withleader41.jpg',
        '/images/public29.jpg',
        '/images/public31.jpg',
        '/images/public32.jpg',
        '/images/public33.jpg',
        '/images/public34.jpg',
        '/images/public35.jpg',
        '/images/public36.jpg',
        '/images/public37.jpg',
        '/images/public38.jpg',
        '/images/public40.jpg',
        '/images/public41.jpg',
        '/images/public43.jpg',
        '/images/public44.jpg',
        '/images/public45.jpg',
        '/images/public46.jpg',
        '/images/public47.jpg',
        '/images/public48.jpg',
        '/images/public49.jpg',
        '/images/public51.jpg',
        '/images/public52.jpg',
        '/images/public53.jpg',
        '/images/public54.jpg',
        '/images/public55.jpg',
        '/images/public56.jpg',
        '/images/public57.jpg',
        '/images/public58.jpg',
        '/images/public59.jpg',
        '/images/public60.jpg',
        '/images/swami1.jpg',
        '/images/swami2.jpg',
        '/images/swami3.jpg',
        '/images/swami4.jpg',
        '/images/swami5.jpg',
        '/images/swami7.jpg'
    ]
};

function GalleryPageComponent() {

    const [stillLoading, setStillLoading] = useState(true);
    const [renderedPhotosObject, setRenderedPhotosObject] = useState([]);
    const [index, setIndex] = useState(-1);

    const createGalleryList = async str => {
        try {
            const {width, height} = await getImageSize(str);
            return {
                src : str,
                width : width || 1,
                height : height || 1
            }
        } catch (e) {
            return {
                src : str,
                width :  1,
                height : 1
            }
        }
    };

    const addDimensionsToGalleryList = async() => {
        const unresolvedPromises = GalleryData.defaultGalleryList.map(createGalleryList);
        const resolvedPromise = await Promise.all(unresolvedPromises);
        setRenderedPhotosObject(resolvedPromise);
        setStillLoading(false);
    };

    useEffect(() => {
        addDimensionsToGalleryList();
    }, []);

    const handleClick = (event, item) => {
        setIndex(item.index);
    }
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const currentImage = renderedPhotosObject[index];
    const nextIndex = (index + 1) % renderedPhotosObject.length;
    const nextImage = renderedPhotosObject[nextIndex] || currentImage;
    const prevIndex = (index + renderedPhotosObject.length - 1) % renderedPhotosObject.length;
    const prevImage = renderedPhotosObject[prevIndex] || currentImage;

    return (
            <div>
                { (!stillLoading)
                    ?
                    (
                        <div>
                            <Gallery photos={renderedPhotosObject} direction={"column"} onClick={handleClick} />
                            {!!currentImage && (
                                /* @ts-ignore */
                                <Lightbox
                                    mainSrc={currentImage.src}
                                    // imageTitle={currentImage.caption}
                                    mainSrcThumbnail={currentImage.src}
                                    nextSrc={nextImage.src}
                                    nextSrcThumbnail={nextImage.src}
                                    prevSrc={prevImage.src}
                                    prevSrcThumbnail={prevImage.src}
                                    onCloseRequest={handleClose}
                                    onMovePrevRequest={handleMovePrev}
                                    onMoveNextRequest={handleMoveNext}
                                />
                            )}
                        </div>

                    ):  <div> Images are loading.. </div>}

                {/*{ (!stillLoading) ? <Gallery images={renderedPhotosObject} enableImageSelection={false} direction={"column"} thumbnailImageComponent={ImageComponent} /> :  <div> Images are loading.. </div>}*/}
            </div>

    );
}
export default function GalleryPage() {
    return (
        <>
            <GalleryPageComponent />
        </>
    )
};
